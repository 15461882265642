











import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios'
import VueAxios from 'vue-axios'
import Notifications from 'vue-notification';


import Vote from "@/components/Vote.vue";
import Instagram from "@/components/Instagram.vue";
// import SocialStream from "@/components/SocialStream.vue";
import SocialVotes from "@/components/SocialVotes.vue";
import Vue2Filters from 'vue2-filters'
import MainScreen from "@/components/MainScreen.vue";

Vue.use(Vue2Filters)
Vue.use(VueAxios, axios);
Vue.use(Notifications)

@Component({
  components: {
    MainScreen,
    SocialVotes,
    // SocialStream,
    Instagram,
    Vote
  },
})
export default class App extends Vue {}
