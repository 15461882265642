
/*global FB*/

import {Component, Prop, Vue} from "vue-property-decorator";
import "facebook-js-sdk";
import Settings from "@/services/Settings";

@Component({})
export default class ModalFacebookVote extends Vue {
  @Prop({default: true}) public show: boolean | undefined;
  @Prop() public item: any;
  @Prop() public topValue: number;
  @Prop() public marginTop: number;

  private readonly voteKey: string = 'tvn-topmodel-vote';

  private storageHost: string;
  private terms: boolean;
  private termsError: boolean;
  private voteSuccess: boolean;
  private voteError: boolean;
  private showVoting: boolean;

  constructor() {
    super();
    const settings = new Settings();
    this.storageHost = settings.getSetting('storagePath');
    this.topValue = 0;
    this.marginTop = 0;
    this.terms = false;
    this.termsError = false;
    this.voteSuccess = false;
    this.voteError = false;
    this.showVoting = true;
    if (localStorage.getItem(this.voteKey) !== null) {
      this.showVoting = parseInt(localStorage.getItem(this.voteKey) as string) === 1;
    }
  }

  voteDescription(value: number) {
    if (value===1) {
      return "głos";
    }
    else if (value===2 || value===3 || value===4) {
      return "głosy";
    }
    else if (value > 20 && (value%10===2 || value%10===3 || value%10===4)) {
      return "głosy"
    }
    else {
      return "głosów";
    }
  }

  facebookLogin() {
    const that = this;
    this.termsError = false;
    this.voteSuccess = false;
    this.voteError = false;
    if (this.terms) {
      FB.login(function(response: any) {
        const authResponse = response;
        if (response.authResponse) {
          FB.api('/me', function(response: any) {
            that.$emit('loginSuccess', {
              auth: authResponse,
              user: response
            });
          });
          that.voteSuccess = true;
        } else {
          that.$emit('loginError');
          that.voteError = true;
        }
      });
    } else {
      this.termsError = true;
    }
  }

  checkTerms() {
    if (this.terms) {
      this.termsError = false;
    }
  }

}

