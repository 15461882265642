

























































import {Component, Vue} from "vue-property-decorator";
import Settings from "@/services/Settings";
import ModalFacebookVote from "@/components/ModalFacebookVote.vue";
// import { scrollIntoView } from "seamless-scroll-polyfill";

@Component({
  filters: {

  },
  components: {
    ModalFacebookVote
  }
})
export default class Vote extends Vue {
  private host: string;
  private storageHost: string;
  private uid: string;
  private loader: boolean;
  private stats: any;
  private topValue: number;

  private showModal: boolean;
  private selectedItem: any;
  private showVoting: boolean;
  private readonly voteKey: string = 'tvn-topmodel-vote';
  private marginTop = 0;
  private screenHeight = 0;

  constructor() {
    super();
    const settings = new Settings();
    this.host = settings.getSetting('apiHost');
    this.storageHost = settings.getSetting('storagePath');
    this.loader = false;
    this.stats = [];
    // this.uid = '424e878ad2c95126c8c9b2ada2dfc03a';
    this.uid = 'fbfe81437324316a3a6b58a6be58ac57';
    this.topValue = 0;
    this.showModal = false;
    this.selectedItem = {};
    this.showVoting = true;
    if (localStorage.getItem(this.voteKey) !== null) {
      this.showVoting = parseInt(localStorage.getItem(this.voteKey) as string) === 1;
    }
  }

  created() {
    this.getData();
  }

  voteDescription(value: number) {
    if (value===1) {
      return "głos";
    }
    else if (value===2 || value===3 || value===4) {
      return "głosy";
    }
    else if (value > 20 && (value%10===2 || value%10===3 || value%10===4)) {
      return "głosy"
    }
    else {
      return "głosów";
    }
  }

  openVotePopup(item: any, $event: any) {
    if (this.showVoting) {
      this.selectedItem = item;
      this.showModal = true;
      console.log($event);
      this.marginTop = $event.clientY;
      // this.screenHeight = $event.screenY;
      this.screenHeight = $event.pageY;
      setTimeout(() => {
        // @ts-ignore: Object is possibly 'null'.
        document.getElementById("app").scrollIntoView();
        // seamless.scrollBy(window, { behavior: "smooth", top: 200, left: 0 });
        // @ts-ignore: Object is possibly 'null'.
        // scrollIntoView(document.querySelector("#app"), { behavior: "smooth", top: 0 });
        window.scrollTo(0,0, { top: 0, behavior: 'smooth' });
        // var scrollToTop = window.setInterval(function() {
        //   var pos = window.pageYOffset;
        //   if ( pos > 0 ) {
        //     window.scrollTo( 0, pos - 20 ); // how far to scroll on each step
        //   } else {
        //     window.clearInterval( scrollToTop );
        //   }
        // }, 16);
      }, 200);

    }
  }

  onFacebookLoginSuccess(event: any) {
    this.saveVote(event.auth);
  }

  onFacebookLoginError() {
    this.$notify({
      group: 'notify',
      type: 'error',
      title: 'Nie udało się zagłosować',
      text: 'Wystąpił błąd w trakcie głosowania'
    });
  }

  saveVote(auth: any) {
    this.$http.post(
        this.host + '/vote', {
          access_token: auth.authResponse.accessToken,
          author_id: auth.authResponse.userID,
          project_uid: this.uid,
          params: this.selectedItem
        }
    ).then((response: any) => {
      console.log(response);
      this.showModal = false;
      this.$notify({
        group: 'notify',
        type: 'success',
        title: 'Głos oddany',
        text: 'Dziękujemy za oddanie głosu!'
      });
      localStorage.setItem(this.voteKey, '0');
      this.showVoting = false;
    }, (error: any) => {
      console.log(error);
      this.showModal = false;
      this.$notify({
        group: 'notify',
        type: 'error',
        title: 'Nie udało się zagłosować',
        text: 'Wystąpił błąd w trakcie głosowania'
      });
    })
  }

  getData() {
    if (this.loader) return;
    this.loader = true;
    this.$http.get(this.host + '/api/nofeed/' + this.uid, {
      params: {}
    }).then((response: any) => {
      this.stats = response.data.stat;
      const values = response.data.stat
          .map((v: any) => v.value)
          .sort((a: number, b: number) => b - a)
          .splice(0, 3)
          .reverse()
      ;
      if (values[0] !== undefined) {
        this.topValue = values[0];
      }
    }, response => {
      console.log(response);
      this.loader = false;
    });
  }
}
