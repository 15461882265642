

























import {Component, Vue} from "vue-property-decorator";
import Settings from "@/services/Settings";

@Component
export default class Instagram extends Vue {
  private host: string;
  private uid: string;
  private loader: boolean;
  private mentions: any[];
  private limit = 6;
  private actualPage = 1;
  private maxPage = 0;

  constructor() {
    super();
    const settings = new Settings();
    this.host = settings.getSetting('apiHost');
    this.loader = false;
    this.mentions = [];
    this.uid = 'e6b827d9318c6db978680bdbf29103f2';

  }

  created() {
    this.getData(1, this.limit);
  }


  getData(page: number, limit: number) {
    if (this.loader) return;
    this.loader = true;
    this.$http.get(this.host + '/apis/' + this.uid + '/feed', {
      params: {
        page: page.toString(),
        limit: limit.toString(),
        sort_by: 'time desc',
        // only_photo: 1
      }
    }).then((response: any) => {
      this.mentions.push(...response.data._embedded.items);
      this.actualPage = response.data.page;
      this.maxPage = response.data.pages;
      this.limit = response.data.limit;
      this.loader = false;
    }, response => {
      console.log(response);
      this.loader = false;
    });
  }
}
