































































































import {Component, Vue} from "vue-property-decorator";
import Settings from "@/services/Settings";

@Component
export default class SocialVotes extends Vue {
  private host: string;
  private uid: string;
  private loader: boolean;
  private stats: any;

  constructor() {
    super();
    const settings = new Settings();
    this.host = settings.getSetting('apiHost');
    this.loader = false;
    this.stats = [];
    // this.uid = '424e878ad2c95126c8c9b2ada2dfc03a';
    this.uid = 'fbfe81437324316a3a6b58a6be58ac57';

  }

  created() {
    this.getData();
  }

  getData() {
    if (this.loader) return;
    this.loader = true;
    this.$http.get(this.host + '/api/stat/' + this.uid, {
      params: {}
    }).then((response: any) => {
      this.stats = response.data;
    }, response => {
      console.log(response);
      this.loader = false;
    });
  }
}
